import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Layout } from "../components/common";
import { processHtml } from "../utils/processHtml";

const Container = styled.div`
  padding-top: 0;
  padding-bottom: 0;
`;

class Thanks extends Component {
  render() {
    const { data } = this.props;
    const { html, plaintext } = data.ghostPage;
    const { htmlX, jumbotronData } = processHtml(html, plaintext);

    return (
      <Layout
        title={data.ghostPage.title}
        subtitle={jumbotronData.subtitle}
        meta={{
          title: data.ghostPage.meta_title,
          description: data.ghostPage.meta_description,
          canonical: data.ghostPage.url,
          featureImage: data.ghostPage.feature_image
        }}
        jumbotronVariant="job"
      >
        <Container>{htmlX}</Container>
      </Layout>
    );
  }
}

Thanks.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      // title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Thanks;

export const pageQuery = graphql`
  query GhostThanksQuery {
    ghostPage(slug: { in: "thanks" }) {
      html
      plaintext
      title
      meta_title
      meta_description
      url
      feature_image
    }
  }
`;

